<template>
  <div>
    <div class="nav-bar">
      <van-icon
        name="arrow-left"
        color="#9B9B9B"
        class="left-icon"
        @click="$router.go(-1)"
      />
      <span>{{ orderStatus > 2 ? "退货申请" : "退款申请" }}</span>
    </div>
    <div class="page-layout">
      <!-- 退款商品信息 -->
      <div class="content-card">
        <div class="shop-title-left">
          {{ orderStatus > 2 ? "退货商品" : "退款商品" }}
        </div>
        <!-- 商品信息 -->
        <div
          class="goods-info"
          v-for="(goods, g_index) in goodsList"
          :key="g_index"
        >
          <div class="goods-images-box">
            <div class="buy-details">
              <div class="goods-img-box">
                <img :src="goods.goods_img" class="goods-img" />
              </div>
              <div class="sku-info">
                <div>{{ goods.goods_name }}</div>
                <span class="specifications">{{ goods.item_key }}</span>
              </div>
            </div>
          </div>
          <div class="price-quantity">
            <div class="price">
              <span>￥</span>
              <span>{{ toInteger(goods.price) }}</span>
              <span>{{ twoFloating(goods.price) }}</span>
            </div>
            <div class="quantity">x{{ goods.goods_num }}</div>
          </div>
        </div>
      </div>
      <!-- 需要退货的商品 -->
      <div class="refund-why">
        <div class="title">{{ orderStatus > 2 ? "退货商品" : "退款商品" }}</div>
        <div class="text-layot">
          <span>{{ orderStatus > 2 ? "退货原因" : "退款原因" }}</span>
          <div class="text" @click="showPopup = true">
            <span>
              {{ radio * 1 >= 0 ? msgs[radio].return_msg : "请选择" }}
            </span>
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="text-layot">
          <span>{{ orderStatus > 2 ? "退货金额" : "退款金额" }}</span>
          <span class="price">￥{{ twoNum(totalMoney) }}</span>
        </div>
      </div>
      <!-- 退货原因描述 -->
      <div class="describe-card">
        <div class="describe-title">补充描述和凭证</div>
        <van-field
          v-model="message"
          rows="2"
          left-icon="edit"
          autosize
          :border="false"
          type="textarea"
          maxlength="100"
          placeholder="上传凭证和填写描述内容"
          show-word-limit
        />
        <van-uploader
          v-model="fileList"
          multiple
          :after-read="commonBase64"
          :max-count="3"
        />
      </div>
      <div class="button" @click="returnOrder()">
        <van-button type="danger" round block>提交</van-button>
      </div>
    </div>
    <van-popup v-model="showPopup" closeable round position="bottom">
      <div class="title-why">
        {{ orderStatus > 2 ? "退货原因" : "退款原因" }}
      </div>
      <van-radio-group v-model="radio" checked-color="#ED301D">
        <van-cell-group>
          <van-cell
            :title="msg.return_msg"
            clickable
            @click="setSelect(m_index)"
            v-for="(msg, m_index) in msgs"
            :key="m_index"
          >
            <template #right-icon>
              <van-radio :name="m_index" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <!--      <div class="button">-->
      <!--        <van-button type="danger" round block>提交</van-button>-->
      <!--      </div>-->
    </van-popup>
  </div>
</template>

<script>
import { getReturnGoods, getReturnReason, returnOrder } from "@/api/order";
import { commonBase64 } from "@/api/common";
import { Toast } from "vant";
import tools from "@/common/js/tools";
export default {
  name: "refund-serve",
  data() {
    return {
      orderId:
        this.$route.query.orderId === undefined ? 0 : this.$route.query.orderId,
      itemId:
        this.$route.query.itemId === undefined ? 0 : this.$route.query.itemId,
      showPopup: false,
      goodsList: [],
      totalMoney: 0,
      radio: -1,
      message: "",
      fileList: [],
      msgs: [],
      msgId: 0,
      isAjax: false,
      orderStatus: this.$route.query.orderStatus,
    };
  },
  watch: {},
  mounted() {
    this.getReturnGoods();
    this.getReturnReason();
  },
  methods: {
    setSelect(radio) {
      this.radio = radio;
      this.showPopup = false;
    },
    integerNum(num) {
      // 获取价格整数部分
      num = this.twoNum(num) + "";

      const result = num.substring(0, num.indexOf("."));
      return result;
    },
    floorNum(num) {
      // 获取价格小数部分
      return tools.twoFloating(num);
      // num = this.twoNum(num) + "";
      // const result = num.substring(num.indexOf("."));
      // return result;
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    async getReturnGoods() {
      //获取退款退货商品列表
      const ret = await getReturnGoods({
        order_id: this.orderId,
        item_id: this.itemId,
      });
      if (ret.code * 1 == 1) {
        this.goodsList = ret.data;
        for (let i = 0; i < this.goodsList.length; i++) {
          this.totalMoney += this.goodsList[i].total_money * 1;
        }
      } else {
        Toast.fail(ret.msg);
      }
    },
    async getReturnReason() {
      const ret = await getReturnReason({ order_id: this.orderId });
      if (ret.code * 1 == 1) {
        this.msgs = ret.data;
      }
    },
    async commonBase64(file) {
      //图片上传
      file.status = "uploading";
      file.message = "上传中...";
      const ret = await commonBase64({ image: file.content });
      if (ret.code * 1 == 1) {
        file.status = "";
        file.message = "";
        file.url = ret.data.url;
      } else {
        file.status = "failed";
        file.message = "上传失败";
      }
    },
    async returnOrder() {
      //退款申请
      if (this.radio < 0) {
        Toast.fail("请选择退货原因");
        return false;
      }
      let imgs = [];
      for (let i = 0; i < this.fileList.length; i++) {
        imgs[i] = this.fileList[i].url;
        if (this.fileList[i].status !== "") {
          Toast.fail(this.fileList[i].message);
          return false;
        }
      }
      let imgsStr = imgs.join(",");
      // return  false;
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      const ret = await returnOrder({
        order_id: this.orderId,
        item_id: this.itemId,
        msg_id: this.msgs[this.radio].id,
        note: this.message,
        imgs: imgsStr,
      });
      this.isAjax = false;
      if (ret.code * 1 == 1) {
        //跳转至退货订单列表
        this.$router.push({ name: "sell-behind", query: { orderType: 1 } });
      } else {
        Toast.fail(ret.msg);
        return false;
      }
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数，自动补零，保留两位小数
      return tools.twoFloating(num);
      // let price = "";
      // price = String(num).split(".")[1];
      // if (price !== undefined && price.length === 1) {
      //   price = `.${price}0`;
      // } else {
      //   price === undefined ? (price = ".00") : (price = `.${price}`);
      // }
      //
      // return price;
    },
  },
};
</script>

<style lang="less" scoped>
// 顶部导航栏
.nav-bar {
  font-size: 20px;
  position: relative;
  background-color: #fff;
  padding: 14px;
  text-align: center;
  border-bottom: 1px solid #f1f1f1;
  .left-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}
.page-layout {
  padding: 12px;
}
// 订单卡片样式
.content-card {
  border-radius: 16px;
  padding: 12px;
  background-color: #fff;

  .shop-title-left {
    font-size: 18px;
    margin: 0 4px;
    font-weight: 500;
    color: #000;
  }
}

// 商品信息
.goods-info {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
  .goods-images-box {
    overflow-x: scroll;
  }
  .buy-details {
    display: flex;
    justify-content: center;
    .goods-img-box {
      border-radius: 6px;
      overflow: hidden;
      .goods-img {
        width: 90px;
        height: 90px;
        display: block;
      }
    }

    .sku-info {
      margin-left: 12px;
      font-size: 17px;
      div {
        width: 150px;
        margin-bottom: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .specifications {
        padding: 4px 13px;
        color: #6f6f6f;
        background-color: #f5f5f5;
        font-size: 16px;
        border-radius: 6px;
        display: -webkit-box; /**对象作为伸缩盒子模型展示**/
        -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
        -webkit-line-clamp: 2; /**显示的行数**/
        overflow: hidden; /**隐藏超出的内容**/
        word-break: break-all; /** 英语字母设定问题，它不会换行在代码中加入强制换行 可以解决这个问题**/
      }
    }
  }

  .price-quantity {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .price {
      font-size: 16px;
    }

    .quantity {
      margin-top: 12px;
      color: #aaaaaa;
      font-size: 16px;
    }
  }
}

.text-color {
  color: #e61f18 !important;
}
// 退货商品
.refund-why {
  padding: 12px;
  background-color: #fff;
  border-radius: 16px;
  margin-top: 12px;
  .title {
    font-weight: 500;
    font-size: 18px;
  }
  .text-layot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    font-size: 16px;
    .text {
      display: flex;
      align-items: center;
      color: #aaaaaa;
      span {
        margin-right: 10px;
      }
    }
    .price {
      color: #ed301d;
    }
  }
}
// 退货原因描述
.describe-card {
  background-color: #fff;
  padding: 12px;
  margin-top: 12px;
  border-radius: 16px;
  .describe-title {
    font-size: 17px;
    font-weight: 500;
  }
}
.button {
  padding: 12px;
}
// 退货原因弹出层
.title-why {
  padding: 20px 0;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.van-cell {
  padding: 16px 12px;
}
.van-cell__title {
  font-size: 16px;
}
</style>
